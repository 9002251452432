'use strict';

const activeClass = 'is-active',
      showClass = 'is-show',
      hideClass = 'is-hide';

const btnShare = '.btn-share',
      btnNav = '.btn-nav';

const burgerMenu = (btnSelector, menuSelector) => {
  const blockLogos = '.expedition-page--places .header__item:first-child';

  $(btnSelector).on('click', function() {
    $(btnSelector).toggleClass(activeClass);
    $(menuSelector).toggleClass(showClass);
    $('.block-helper').toggleClass(showClass);

    if($(menuSelector).hasClass(showClass)) {
      $(btnShare).addClass(hideClass);
      $(btnNav).addClass(hideClass);
      $(blockLogos).addClass(hideClass);
    } else {
      $(btnShare).removeClass(hideClass);
      $(btnNav).removeClass(hideClass);
      $(blockLogos).removeClass(hideClass);
    }
  });
};

const share = (btnSelector, shareSelector, time) => {
  const parent = $(shareSelector).parent();
  let hide;

  $(btnSelector).on('click', function() {
    let _parent = $(this).parent();
    $(this).toggleClass(activeClass);
    _parent.find(shareSelector).toggleClass(showClass);
  });
  
  function hidebyTime() {
    $(parent).on('mouseleave', function() {
      if($(shareSelector).hasClass(showClass)) {
        hide = setTimeout(function() {
          $(btnSelector).removeClass(activeClass);
          $(shareSelector).removeClass(showClass);
        }, time);
      }
    });
  
    $(parent).on('mouseenter', function() {
      if($(shareSelector).hasClass(showClass)) {
        clearTimeout(hide);
      }
    });
  };

  hidebyTime();
};

const scrollDown = (btnSelector, sectionSelector) => {
  $(btnSelector).on('click', function(e) {
    if(e.target) {
      e.preventDefault();
    }
    $('html,body').animate({
      scrollTop: $(sectionSelector).offset().top},
      'slow');
  });
};

const video = (btnSelector, videoSelector, wrapper) => {
  $(btnSelector).on('click', function() {
    parent = $(this).closest(wrapper);
    parent.find(videoSelector).addClass(showClass).find('iframe')[0].src += '?autoplay=1';
  });
};

const tabs = function(parent, tab, attr, contentImg, contentText) {
  $(parent).on('click', tab, function(e) {
    e.preventDefault();

    const id = $(this).attr(attr);
    const contentElemImg = $(`${contentImg}[${attr}="${id}"]`);
    const contentElemText = $(`${contentText}[${attr}="${id}"]`);

    $(`${tab}.is-active`).removeClass(activeClass);
    $(this).addClass(activeClass);
    $(`${contentImg}.is-show`).removeClass(showClass);
    $(`${contentText}.is-show`).removeClass(showClass);
    contentElemImg.addClass(showClass);
    contentElemText.addClass(showClass);
  });

  function setData(elem, attr) {
    $(elem).each(function (i, it) {
      $(it).attr(attr, i);
    });
  };

  setData('.facts__item-img', 'data-fact');
  setData('.facts__tabs-item', 'data-fact');
  setData('.facts__desc-item', 'data-fact');
};

const fullPage = () => {
  $('#fullpage').fullpage({
    anchors: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
    navigation: true,
    scrollBar: true,
    // scrollOverflow: false,
    normalScrollElements: '.scroll-inside',
    paddingTop: '0',
    paddingBottom: '0',
    afterRender: function() {
      animationPage('#fullpage');
    },
    onLeave: function(index, nextIndex, direction) {
      if(nextIndex.index === 0) {
        hideMenu('.btn-menu', '.menu');
      }
    },
  });

  if($('#fullpage').length > 0) {
    destroyPlugin('(max-width: 767px)', fullpage_api);

    $(window).on('resize', function() {
      destroyPlugin('(max-width: 767px)', fullpage_api);
    });
  }

  function destroyPlugin(media, plugin) {
    if (window.matchMedia(media).matches) {
      plugin.destroy('all');
    }
  };

  function hideMenu(btnSelector, menu) {
    if($(menu).hasClass(showClass)) {
      $(btnSelector).click();
    }
  };
};

const animationPage = (pageSelector) => {
  if($(pageSelector).length > 0) {
    AOS.init({
      startEvent: 'DOMContentLoaded',
      easing: 'ease-in-out',
      duration: 1400,
      once: true,
    });
  }
};

const quiz = () => {
  const quizData = [
    {
      image: '/static/img/content/quiz/img_eumetopias.jpg',
      question: 'Кто живет в гаремах из одного самца и нескольких самок с детенышами?',
      correctAnswer: 2,
      descCorrect: 'Да, это сивуч!',
      descWrong: 'Вы не угадали, это сивуч :(',
    },
    {
      image: '/static/img/content/quiz/img_enhydra.jpg',
      question: 'Кто питается в основном морскими ежами и моллюсками?',
      correctAnswer: 1,
      descCorrect: 'Да, это калан!',
      descWrong: 'Вы не угадали, это калан :(',
    },
    {
      image: '/static/img/content/quiz/img_eumetopias.jpg',
      question: 'Кто умеет громко и устрашающе реветь?',
      correctAnswer: 2,
      descCorrect: 'Да, это сивуч!',
      descWrong: 'Вы не угадали, это сивуч :(',
    },
    {
      image: '/static/img/content/quiz/img_enhydra.jpg',
      question: 'Кто заворачивается в водоросли перед сном?',
      correctAnswer: 1,
      descCorrect: 'Да, это калан!',
      descWrong: 'Вы не угадали, это калан :(',
    },
    {
      image: '/static/img/content/quiz/img_enhydra.jpg',
      question: 'На кого охотились ради роскошного меха?',
      correctAnswer: 1,
      descCorrect: 'Да, это калан!',
      descWrong: 'Вы не угадали, это калан :(',
    },
    {
      image: '/static/img/content/quiz/img_eumetopias.jpg',
      question: 'Кто может глубже нырнуть?',
      correctAnswer: 2,
      descCorrect: 'Да, это сивуч!',
      descWrong: 'Вы не угадали, это сивуч :(',
    },
    {
      image: '/static/img/content/quiz/img_enhydra.jpg',
      question: 'Кого называли морским бобром?',
      correctAnswer: 1,
      descCorrect: 'Да, это калан!',
      descWrong: 'Вы не угадали, это калан :(',
    },
    {
      image: '/static/img/content/quiz/img_eumetopias.jpg',
      question: 'Кого можно встретить в бухте Петропавловска-Камчатского?',
      correctAnswer: 2,
      descCorrect: 'Да, это сивуч!',
      descWrong: 'Вы не угадали, это сивуч :(',
    },
    {
      image: '/static/img/content/quiz/img_enhydra.jpg',
      question: 'О ком натуралист Георг Стеллер писал в XIX веке следующие строки: “Часто самки принимаются играть с детенышем: они подбрасывают его кверху, словно мячик, сталкивают в воду, чтобы он научился плавать, а когда он устает, снова берут на руки и целуют совсем по-человечьи”?',
      correctAnswer: 1,
      descCorrect: 'Да, это калан!',
      descWrong: 'Вы не угадали, это калан :(',
    },
    {
      image: '/static/img/content/quiz/img_eumetopias.jpg',
      question: 'Кого относят к группе ластоногих?',
      correctAnswer: 2,
      descCorrect: 'Да, это сивуч!',
      descWrong: 'Вы не угадали, это сивуч :(',
    },
  ];

  const bgClass = {
    content: 'quiz-page--content',
    result: 'quiz-page--result',
  };

  const finalResultText = {
    bad: 'Кажется, вы знаете о каланах и сивучах далеко не все. Почитайте о них на странице <a href="/">нашего проекта</a>; откроете для себя много нового и интересного!',
    average: 'Кажется, кое-каких подробностей из жизни каланов и сивучей вы все же не знаете. Ничего страшного; это всегда <a href="/">можно исправить.</a>',
    good: 'Неплохо! Промахнулись в нескольких местах, но в целом каланы и сивучи могут гордиться вашими знаниями.',
  };

  let counterCorrectAnswer = 0,
      counterQuestion = 0;

  const quizPage = '.quiz-page',
        contentBlock = '.quiz__content',
        questionContent = '.question',
        answerResultContent = '.answer-result',
        resultBlock = '.quiz__result',
        questionNumCurrent = '.question-num__current',
        questionNumTotal = '.question-num__total',
        questionTitle = '.question__title',
        answersList = '.answers',
        answersItem = '.answers__item',
        answerResultTitle = '.answer-result__title',
        smilesBlock = '.smiles',
        btnStart = '.btn-start',
        btnNext = '.btn-next',
        btnAnswerClass = 'btn-answer',
        btnAgain = '.btn-again',
        resultBar = '.result__bar',
        resultText = '.result__text';

  setAnimation('.quiz__intro .title', 300);
  setAnimation('.quiz__intro-desc', 900);
  setAnimation(btnStart, 1200);

  function setAnimation(elem, delay) {
    $(elem).hide().delay(delay).fadeIn('slow');
  };
  
  function nextQuestion() {
    counterQuestion += 1;
    $(questionNumCurrent).text(counterQuestion + 1);

    if (counterQuestion < quizData.length) {
      $(btnNext).attr('disabled', true);
      $(questionContent).show();
      $(answerResultContent).hide();
      $(answersList).html('');
      createQuestion(counterQuestion, questionTitle);
    } else {
      $(contentBlock).hide();
      $(resultBlock).show();
      $(quizPage).removeClass(bgClass.content).addClass(bgClass.result);
      let url;
      
      switch (counterCorrectAnswer) {
        case 1:
          testResult(
            counterCorrectAnswer,
            `.result__bar-item:nth-child(-n+${counterCorrectAnswer})`,
            finalResultText.bad
          );
          url = 'https://kinder.nat-geo.ru/quiz-results/quiz-result-1/';
          break;
        case 2:
          testResult(
            counterCorrectAnswer,
            `.result__bar-item:nth-child(-n+${counterCorrectAnswer})`,
            finalResultText.bad
          );
          url = 'https://kinder.nat-geo.ru/quiz-results/quiz-result-2/';
          break;
        case 3:
          testResult(
            counterCorrectAnswer,
            `.result__bar-item:nth-child(-n+${counterCorrectAnswer})`,
            finalResultText.bad
          );
          url = 'https://kinder.nat-geo.ru/quiz-results/quiz-result-3/';
          break;
        case 4:
          testResult(
            counterCorrectAnswer,
            `.result__bar-item:nth-child(-n+${counterCorrectAnswer})`,
            finalResultText.average
          );
          url = 'https://kinder.nat-geo.ru/quiz-results/quiz-result-4/';
          break;
        case 5:
          testResult(
            counterCorrectAnswer,
            `.result__bar-item:nth-child(-n+${counterCorrectAnswer})`,
            finalResultText.average
          );
          url = 'https://kinder.nat-geo.ru/quiz-results/quiz-result-5/';
          break;
        case 6:
          testResult(
            counterCorrectAnswer,
            `.result__bar-item:nth-child(-n+${counterCorrectAnswer})`,
            finalResultText.average
          );
          url = 'https://kinder.nat-geo.ru/quiz-results/quiz-result-6/';
          break;
        case 7:
          testResult(
            counterCorrectAnswer,
            `.result__bar-item:nth-child(-n+${counterCorrectAnswer})`,
            finalResultText.good
          );
          url = 'https://kinder.nat-geo.ru/quiz-results/quiz-result-7/';
          break;
        case 8:
          testResult(
            counterCorrectAnswer,
            `.result__bar-item:nth-child(-n+${counterCorrectAnswer})`,
            finalResultText.good
          );
          url = 'https://kinder.nat-geo.ru/quiz-results/quiz-result-8/';
          break;
        case 9:
          testResult(
            counterCorrectAnswer,
            `.result__bar-item:nth-child(-n+${counterCorrectAnswer})`,
            finalResultText.good
          );
          url = 'https://kinder.nat-geo.ru/quiz-results/quiz-result-9/';
          break;
        case 10:
          testResult(
            counterCorrectAnswer,
            `.result__bar-item:nth-child(-n+${counterCorrectAnswer})`,
            'Снимаем шляпу и уважительно машем ластами, отличный результат! Вы настоящий сивучевед и каланоэрудит!'
          );
          url = 'https://kinder.nat-geo.ru/quiz-results/quiz-result-10/';
          break;
        default:
          testResult(
            counterCorrectAnswer,
            `.result__bar-item:nth-child(-n+${counterCorrectAnswer})`,
            'Кажется, вы ничего не знаете о каланах и сивучах'
          );
          url = 'https://kinder.nat-geo.ru/quiz-results/quiz-result-0/';
          break;
      }

      $('.social-list .telegram').attr('href', `tg://msg_url?url=${url}&amp;text=В мире каланов и сивучей. Викторина УГАДАЙ, КТО?`)

      $('.share-quiz .social-list').socialLikes({
        url: url,
        counters: false,
      });
    }
  };

  function onClickBtnStart(btnSelector, startContent, contentBlock) {
    $(btnSelector).on('click', function(e) {
      e.preventDefault();
      $(quizPage).addClass(bgClass.content);
      createQuestion(counterQuestion, questionTitle);
      $(startContent).hide();
      $(contentBlock).show();
      $(questionContent).show();
    });
  };

  function onClickBtnAgain(btnSelector, contentAnswers) {
    $(btnSelector).on('click', function(e) {
      e.preventDefault();
      counterCorrectAnswer = 0;
      counterQuestion = 0;

      $(questionNumCurrent).text('1');
      $(contentAnswers).html('');

      createQuestion(counterQuestion, questionTitle);
      $(resultBlock).hide();
      $(answerResultContent).hide();
      $(contentBlock).show();
      $(questionContent).show();

      $(quizPage).removeClass(bgClass.result).addClass(bgClass.content);
    });
  };

  function createQuestion(id, title) {
    const question = quizData[id].question;
    $(title).text(question);
    $(questionNumTotal).text(quizData.length);
    setAnimation('.quiz__content-header', 300);
    setAnimation(questionTitle, 300);
    renderAnswers(answersList);
    setAnimation('.answers__item:nth-child(1)', 600);
    setAnimation('.answers__item:nth-child(2)', 900);
    setAnimation(btnNext, 1200);
    $(btnNext).addClass(btnAnswerClass);
  };

  function renderAnswers(wrap) {
    let teamplate = `
      <a class="answers__item" href="#" data-answer="1">
        <div class="answers__img-wrap">
          <div class="answers__img quiz-img">
            <img class="cover" src="/static/img/content/quiz/img_enhydra.jpg", alt="калан">
          </div>
          </div>
        <p class="answers__text">
          <span>a</span> калан
        </p>
      </a>
      <a class="answers__item" href="#" data-answer="2">
        <div class="answers__img-wrap">
          <div class="answers__img quiz-img">
            <img class="cover" src="/static/img/content/quiz/img_eumetopias.jpg", alt="сивуч">
          </div>
          </div>
        <p class="answers__text">
          <span>б</span> сивуч
        </p>
      </a>`
    $(wrap).append(teamplate);
  };

  function onClickAnswer(contentAnswers, answer) {
    $(contentAnswers).on('click', answer, function(e) {
      e.preventDefault();

      if ($(this).hasClass(activeClass)) {
        $(this).removeClass(activeClass);
      } else {
        $(answer).removeClass(activeClass);
        $(this).addClass(activeClass);
      }
      $(btnNext).attr('disabled', false);
    });
  };

  function showAnswer() {
    $(questionContent).hide();
    
    const _this = $('.answers__item.is-active');
    const dataAnswer = parseInt($(_this).data('answer'));
    const smileWrongClass = 'smiles--wrong';
    const moveUpClass = 'move-up';
    const moveDownClass = 'move-down';
    const currentAnswer = quizData[counterQuestion].correctAnswer;
    const correctAnswer = quizData[counterQuestion].descCorrect;
    const wrongAnswer = quizData[counterQuestion].descWrong;
    const resultAnswerImg = quizData[counterQuestion].image;

    $('.answer-result__img img').attr('src', resultAnswerImg);

    setAnimation(answerResultTitle, 300);
    setAnimation('.answer-result__img', 600);
    setAnimation(btnNext, 1500);
    $(answerResultContent).show();

    if (dataAnswer === currentAnswer) {
      counterCorrectAnswer += 1;
      $(_this).removeClass(activeClass);
      $(answerResultTitle).text(correctAnswer);
      $(smilesBlock).addClass(moveUpClass);
      $(smilesBlock).removeClass(`${smileWrongClass} ${moveDownClass}`);
    } else {
      $(answerResultTitle).text(wrongAnswer);
      $(smilesBlock).removeClass(moveUpClass);
      $(smilesBlock).addClass(`${smileWrongClass} ${moveDownClass}`);
    }

    $(btnNext).removeClass(btnAnswerClass);
  };

  function createResultBar(wrap) {
    let teamplate = '';
    for (let i = 0; i < quizData.length; i++) {
      teamplate += `
        <div class="result__bar-item"></div>
      `;
    }
    $(wrap).html('').append(teamplate);
  };

  function onClickNextBtn(btnSelector) {
    $(btnSelector).on('click', function(e) {
      e.preventDefault();
      if(!$(btnSelector).hasClass(btnAnswerClass)) {
        nextQuestion();
      } else {
        showAnswer();
      }
    });
  };

  function testResult(result, barSelector, text) {
    $('.result__num-correct').text(result);
    $('.result__num-total').text(quizData.length);
    createResultBar(resultBar);
    $(barSelector).addClass('is-orange');
    $(resultText).html(text);
    setAnimation('.result__num', 300);
    setAnimation(resultBar, 300);
    setAnimation(resultText, 600);
    setAnimation('.share-quiz', 900);
    setAnimation(btnAgain, 1200);
  };

  onClickBtnStart(btnStart, '.quiz__intro', contentBlock);
  onClickAnswer(answersList, answersItem);
  onClickNextBtn(btnNext);
  onClickBtnAgain(btnAgain, answersList);
};

const slider = (selector) => {
  let image = 'img.cover';

  $(selector).on('init reinit beforeChange', (event, slick, currentSlide, nextSlide) => {
    changeCaption('.slider__caption', slick.$slides, nextSlide);
  });

  function changeCaption(captionSelector, slide, num) {
    let startTitle = slide.eq(0).find(image).attr('alt'),
        title = slide.eq(num).find(image).attr('alt');
        
    let newTitle = title ? title : startTitle;
    $(captionSelector).hide().fadeIn(300).text(newTitle);
  };

  $(selector).slick({
    adaptiveHeight: true,
    dots: true,
    speed: 700,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        }
      },
    ],
  });
};

const expedition = () => {
  const body = 'body',
        placesSection = '.places__section',
        inActiveClass = 'is-inactive',
        popupImg = '.popup-photo__img img',
        expeditionNavItem = '.expedition-nav__item';

  function setAttr(elem, attr) {
    $(elem).each(function (i, item) {
      $(item).attr(attr, i);
    });
  };

  function scrollTo(wrap, elem) {
    $(wrap).on('click', elem, function() {
      let _this = $(this),
          id = _this.data('section');

      setTimeout(function() {
        _this.closest('.popup').removeClass(showClass);
        $(body).removeClass(inActiveClass);
      }, 700);

      if($(`#${id}`).length > 0) {
        $('html, body').animate({
          scrollTop: $(`#${id}`).offset().top - 145,
        }, 'slow');
      }
    });
  };

  function fadeContent(section, scrollTop) {
    let children = $(section).children();
    children.each(function(i, item) {
      let _this = $(item),
          elemTop = _this.offset().top - 200;

      if(scrollTop > elemTop) {
        if(_this.height() >= 500) {
          _this.css('opacity', 1 - (scrollTop - elemTop) / 700);
        } else if (_this.height() <= 250) {
          _this.css('opacity', 1 - (scrollTop - elemTop) / 120);
        } else {
          _this.css('opacity', 1 - (scrollTop - elemTop) / 400);
        }
      } else if(scrollTop <= 0) {
        children.eq(0).css('opacity', 1);
        _this.css('opacity', 1);
      } else {
        _this.css('opacity', 1);
      }
    });
  };

  function bindPopup(trigger, popup, btnClose) {
    $(trigger).on('click', function(e) {
      if(e.target) {
        e.preventDefault();
      }
      
      let srcImage = $(this).find('.photo-tooltip__img img').attr('src');
      $(popupImg).attr('src', srcImage);
      $(popup).addClass(showClass);

      if (window.matchMedia('(max-width: 1279px)').matches) {
        $(body).addClass(inActiveClass);
      }
    });

    $(btnClose).on('click', function() {
      $(popupImg).attr('src', '');
      $(popup).removeClass(showClass);
      $(body).removeClass(inActiveClass);
    });

    $(popup).on('click', function(e) {
      let classPopup = popup.split('.')[1];

      if($(e.target).hasClass(classPopup)) {
        $(popupImg).attr('src', '');
        $(popup).removeClass(showClass);
        $(body).removeClass(inActiveClass);
      }
    });
  };

  $(window).on('scroll', function() {
    let scrollTop = $(this).scrollTop();
    fadeContent(placesSection, scrollTop);
  });

  setAttr(expeditionNavItem, 'data-section');
  setAttr(placesSection, 'id');
  scrollTo('.expedition-nav__list', expeditionNavItem);
  slider('.slider__list');

  if (window.matchMedia('(max-width: 1279px)').matches) {
    bindPopup('.photo-tooltip', '.popup-photo', '.popup-photo .btn-close');
  }

  bindPopup(btnNav, '.popup-expedition-nav', '.popup-expedition-nav .btn-close');
};  

const socialLikes = (selector) => {
  $(selector).socialLikes({
    counters: false,
  });
};

$(function () {
  animationPage('#main-page');
  animationPage('#expedition-page');
  fullPage();
  burgerMenu('.btn-menu', '.menu');
  share(btnShare, '.social-list', 3500);
  scrollDown('.scroll-down', '#section-scroll');
  scrollDown('.nav-list__item-brands', '#section-footer');
  scrollDown('.menu__list-item-brands', '#section-footer');
  video('.btn-play', '.section__video', '.section');
  tabs('.facts__tabs', '.facts__tabs-item', 'data-fact', '.facts__item-img', '.facts__desc-item');
  quiz();
  expedition();
  socialLikes('.share .social-list');
});
